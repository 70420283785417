import type { JSX, Component } from "solid-js";

import Main from './Main';
import Layout from "./Layout";
import GetAccessToken from "./GetAccessToken";
import { useAccessToken } from "../contexts/WaniKani";


const App: Component = (): JSX.Element => { 
  const { isSetup } = useAccessToken();

  return (
    <Layout>
      {!isSetup() && <GetAccessToken />}
      {isSetup() && <Main />}
    </Layout>
  )
}

export default App
