import { Component, JSX } from "solid-js";

import styles from "./RangeSlider.module.scss";
import { useField } from "./Field";
import range from "lodash-es/range";

export type RangeSliderProps = {
  value: number, 
  onValueChanged: (value: number) => void,

  min: number,
  max: number,
  ticks?: number[],
  
  help?: JSX.Element,
};

const RangeSlider: Component<RangeSliderProps> = (props): JSX.Element => {
  const { name, id } = useField();

  const handleChange: JSX.ChangeEventHandler<HTMLInputElement, Event> = (e) => {
    props.onValueChanged(parseInt(e.currentTarget.value));
  };

  const ticks = props.ticks || range(props.min, props.max + 1, 1);
  const calcLeft = (value: number): number => {
    
    return ((value - props.min) * 100) / (props.max - props.min);
  };

  return (
    <div class={styles.rangeSlider}>
      {props.help && (
        <p class={styles.help}>{props.help}</p>
      )}

      <div class={styles.container}>
        <input type="range" id={id} name={name} value={props.value} min={props.min} max={props.max} step={1} onChange={handleChange} list={`${id}_markers`} />
        <datalist id={`${id}_markers`} class={styles.datalist}>
          {ticks.map((i) => <option value={i} label={i.toString()} style={`--left: ${calcLeft(i)}%`}>{i}</option>)}
        </datalist>
      </div>
    </div>
  )
};

export default RangeSlider;
