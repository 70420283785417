import { SubjectType } from "../clients/WaniKani";

class SummaryResource {
  private _typedSubjectIds: Record<SubjectType, number[]>;

  constructor(typedSubjectIds: Record<SubjectType, number[]>) {
    this._typedSubjectIds = typedSubjectIds;
  }

  get reviewCount(): number {
    return this.subjectIds.length;
  }

  get subjectIds(): number[] {
    return this._typedSubjectIds.vocabulary;
  }
}

export default SummaryResource;