import Dexie, { type EntityTable } from "dexie";
import { DateTime, Duration } from "luxon";

interface Record {
  id: number;
  key: string;
  expiresAt?: number;
  data: string;
}

const db = new Dexie("Cache") as Dexie & {
  records: EntityTable<Record, "id">;
};

db.version(1).stores({
  records: "++id, &key, expiresAt"
});

export class IndexedDbStore {
  async get<T extends string>(key: string): Promise<T | null> {
    const record = await db.records.get({ key });

    if (record) { 
      if (record.expiresAt && record.expiresAt < DateTime.now().toMillis()) {
        await db.records.delete(record.id);

        return null;
      } else {
        return record.data as T;
      }
    } else {
      return null;
    }
  }

  async set(key: string, data: string, expiresIn?: Duration): Promise<void> {
    try {
      await db.records.add(
        {
          key,
          data,
          expiresAt: expiresIn ? DateTime.now().plus(expiresIn).toMillis() : undefined,
        }
      )
    } catch (e: any) {
      if (e.name === "ConstraintError") {
        return;
      } else {
        throw e;
      }
    }
  }
}