import { Component, JSX, ParentProps } from "solid-js";

import styles from "./Card.module.scss";

const Card: Component<ParentProps> = (props): JSX.Element => {
  return (
    <div class={styles.card}>{props.children}</div>
  );
};

export default Card;