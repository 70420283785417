import { Component, JSX, ParentProps } from "solid-js";

import styles from "./Skeleton.module.scss";

export const TextSkeleton: Component<{ text: string }> = (props): JSX.Element => {
  return <span class={styles.line}>{props.text}</span>;
};

export const BlockSkeleton: Component<ParentProps> = (props): JSX.Element => {
  return <div class={styles.block}>{props.children}</div>;
};