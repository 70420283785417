/* @refresh reload */
import * as Sentry from "@sentry/solid";
import { render } from "solid-js/web"
import "./index.scss"
import App from "./components/App";

import { Provider as StorageProvider } from "./contexts/Storage";
import { Provider as WaniKaniProvider } from "./contexts/WaniKani";

Sentry.init({
  dsn: "https://fe18557c4324b96cd817330a28353061@o4508404301168640.ingest.us.sentry.io/4508404304314368",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"], //, /^https:\/\/yourserver\.io\/api/], - this should probably be /api/segments /api/summary
});

const root = document.getElementById("root")

render(() => (
  <StorageProvider>
    <WaniKaniProvider>
      <App />
    </WaniKaniProvider>
  </StorageProvider>
), root!)
