import { Component, JSX, ParentProps } from "solid-js";

import styles from "./Heading.module.scss";
import clsx from "clsx";

const Heading: Component<ParentProps<{ level?: number }>> = (props): JSX.Element => {
  return (
    <div class={clsx(styles.heading, styles[`heading-${props.level || 3}`])}>
      {props.children}
    </div>
  );
};

export default Heading;