import { type JSX, type Component } from "solid-js";
import Field from "./Forms/Field";
import RadioButton from "./Forms/RadioButton";
import { DigestSettings, ReviewSeparator, ReviewStyle } from "../services/Generator";
import { SettingsUpdaterFn } from "./GenerateForm";
import RangeSlider from "./Forms/RangeSlider";

const SettingsForm: Component<{ settings: DigestSettings, updateSettings: SettingsUpdaterFn }> = (props): JSX.Element => {
  const updateReviewStyle = (value: ReviewStyle) => {
    return () => {
      props.updateSettings((settings) => settings.reviewStyle = value);
    };
  }

  const updateReviewSeparator = (value: ReviewSeparator) => {
    return () => {
      props.updateSettings((settings) => settings.reviewSeparator = value);
    };
  }

  const updatePauseBeforeAnswerDuration = (value: number) => {
      props.updateSettings((settings) => settings.pauseBeforeAnswerDuration = value);
  }

  return (
    <>
      <Field name="reviewStyle">
        <Field.Label>Review Style</Field.Label>

        <Field.Group>
          <RadioButton
            value={ReviewStyle.JapaneseFirst} 
            selected={props.settings.reviewStyle == ReviewStyle.JapaneseFirst} 
            onSelected={updateReviewStyle(ReviewStyle.JapaneseFirst)}

            label="Japanese first"
            help={<>Great for improving Japanese comprehension as you hear and process Japanese prompts. This option strengthens listening skills but may take longer to build recall for speaking. <strong>This is what WaniKani does.</strong></>}
          />

          <RadioButton
            value={ReviewStyle.EnglishFirst} 
            selected={props.settings.reviewStyle == ReviewStyle.EnglishFirst} 
            onSelected={updateReviewStyle(ReviewStyle.EnglishFirst)}

            label="English first"
            help={<>Ideal for practicing active recall and speaking. This format helps you quickly translate thoughts into Japanese but may feel challenging for beginners. <strong>This is what KaniWani does.</strong></>}
          />

          <RadioButton
            value={ReviewStyle.Randomize} 
            selected={props.settings.reviewStyle == ReviewStyle.Randomize} 
            onSelected={updateReviewStyle(ReviewStyle.Randomize)}

            label="Randomize"
            help="A balanced mix of both styles. This approach reinforces both comprehension and active recall but may be less predictable, which can be both a challenge and an advantage for memory retention."
          />
        </Field.Group>
      </Field>

      <Field name="pauseBeforeAnswerDuration">
        <Field.Label>Pause before reading answer</Field.Label>

        <RangeSlider 
          value={props.settings.pauseBeforeAnswerDuration}
          onValueChanged={updatePauseBeforeAnswerDuration}
          min={1}
          max={30}
          ticks={[1, 3, 5, 7, 10, 15, 20, 30]}
          help="The number of seconds between hearing the question and the answer. This delay gives you time to think and respond before the answer is revealed."
        />
      </Field>

      <Field name="reviewSeparator">
        <Field.Label>Review Seperator</Field.Label>

        <Field.Group>
          <RadioButton
            value={ReviewSeparator.ReviewNumber} 
            selected={props.settings.reviewSeparator == ReviewSeparator.ReviewNumber} 
            onSelected={updateReviewSeparator(ReviewSeparator.ReviewNumber)}

            label="Review number"
            help={`Announces the review number (e.g. "Review 1") to help you track your progress.`}
          />

          <RadioButton
            value={ReviewSeparator.Pause} 
            selected={props.settings.reviewSeparator == ReviewSeparator.Pause} 
            onSelected={updateReviewSeparator(ReviewSeparator.Pause)}

            label="Pause"
            help="A brief moment of silence to give your mind a reset before the next review."
          />

          <RadioButton
            value={ReviewSeparator.Gong} 
            selected={props.settings.reviewSeparator == ReviewSeparator.Gong} 
            onSelected={updateReviewSeparator(ReviewSeparator.Gong)}

            label="Gong!"
            help="A gentle sound as a cue to mark the transition between reviews, because Japan."
          />


        </Field.Group>
      </Field>
    </>
  );
};

export default SettingsForm;