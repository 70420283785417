import { Component, JSX, ParentProps } from "solid-js";
import styles from "./Layout.module.scss";
import Background from "./Background";
import Heading from "./Heading";

export const Layout: Component<ParentProps> = (props): JSX.Element => {
  return (
    <div class={styles.layout}>
      <div class={styles.bottomLayer}>
        <Background />
      </div>
      <div class={styles.topLayer}>
        <div class={styles.logo}><Heading level={1}>Durtle Daily</Heading></div>
        {props.children}
      </div>
    </div>
  )
}

export default Layout
