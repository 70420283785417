import { type JSX, type Component } from "solid-js";
import Field from "./Forms/Field";
import RadioButton from "./Forms/RadioButton";
import { AudioFormat, DigestSettings, Mp3AudioFormat, Mp3Quality, OutputType } from "../services/Generator";
import { SettingsUpdaterFn } from "./GenerateForm";

const defaultAudioFormat = (type: OutputType): AudioFormat => {
  switch (type) {
    case OutputType.Mp3:
      return { type, quality: Mp3Quality.Medium };
    case OutputType.Wave: 
      return { type };
  }
}

const AudioSettingsForm: Component<{ settings: DigestSettings, updateSettings: SettingsUpdaterFn }> = (props): JSX.Element => {
  const updateOutputType = (type: OutputType) => {
    return () => {
      props.updateSettings((settings) => settings.audioFormat = defaultAudioFormat(type));
    };
  }

  const updateQuality = (quality: Mp3Quality) => {
    return () => {
      props.updateSettings((settings) => settings.audioFormat = { ...(defaultAudioFormat(OutputType.Mp3) as Mp3AudioFormat), quality });
    };
  }

  return (
    <>
      <Field name="audioType">
        <Field.Label>Audio type</Field.Label>

        <Field.Group>
          <RadioButton
            value={OutputType.Wave} 
            selected={props.settings.audioFormat.type == OutputType.Wave} 
            onSelected={updateOutputType(OutputType.Wave)}

            label="Wave"
            help="If you're only planning on listening in your browser, this is the best option."
          />

          <RadioButton
            value={OutputType.Mp3} 
            selected={props.settings.audioFormat.type == OutputType.Mp3} 
            onSelected={updateOutputType(OutputType.Mp3)}

            label="MP3"
            help="If you plan to download the output, you'll get a much smaller file if you output to MP3, though it can take a minute or two to encode it."
          />
        </Field.Group>
      </Field>

      {props.settings.audioFormat.type == OutputType.Mp3 && (
        <Field name="mp3Quality">
          <Field.Label>Quality</Field.Label>

          <Field.Group>
            <RadioButton
              value={Mp3Quality.Low} 
              selected={(props.settings.audioFormat as Mp3AudioFormat).quality == Mp3Quality.Low} 
              onSelected={updateQuality(Mp3Quality.Low)}

              label="Low quality"
              help="The download size will be smaller but the quality of the audio may suffer a little. It will encode slightly quicker."
            />

            <RadioButton
              value={Mp3Quality.Medium} 
              selected={(props.settings.audioFormat as Mp3AudioFormat).quality == Mp3Quality.Medium} 
              onSelected={updateQuality(Mp3Quality.Medium)}

              label="Medium quality"
              help="A good middle ground where the download size and quality will be reasonable."
            />

            <RadioButton
              value={Mp3Quality.High} 
              selected={(props.settings.audioFormat as Mp3AudioFormat).quality == Mp3Quality.High} 
              onSelected={updateQuality(Mp3Quality.High)}

              label="High quality"
              help="The download size will be bigger but the quality of the audio will be crisp and clear. It may take a little longer to process."
            />
          </Field.Group>
        </Field>
      )}
    </>
  );
};

export default AudioSettingsForm;