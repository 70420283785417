import { JSX } from "solid-js/jsx-runtime";
import { Component, ParentProps } from "solid-js";

import styles from "./Pill.module.scss";
import clsx from "clsx";

export enum PillStyle {
  Default = "default",
  Info = "info",
  Success = "success",
  Error = "error"
};

const Pill: Component<ParentProps<{ skeleton?: boolean, style: PillStyle }>> = (props): JSX.Element => {
  return (
    <div class={clsx(styles.pill, styles[props.style], props.skeleton && styles.skeleton)}>
      {props.children}
    </div>
  );
};

export default Pill;