import { Duration } from "luxon";
import { IndexedDbStore } from "../services/IndexedDbStore";
import UserResource from "../resources/UserResource";
import SummaryResource from "../resources/SummaryResource";
import uniq from "lodash-es/uniq";
import { subjectTypes } from "./Backend";

export enum SubjectType {
  Radical = "radical",
  Kanji = "kanji",
  Vocabulary = "vocabulary",
  KanaVocabulary = "kana_vocabulary"
};

export class AuthError extends Error {
  constructor() {
    super("Invalid token");
    this.name = "AuthError";
  }
};

export async function validateAccessToken(accessToken: string): Promise<boolean> {
  const response = await fetch(`https://api.wanikani.com/v2/user`, 
    { 
      method: "GET", 
      headers: new Headers({
        "Authorization": `Bearer ${accessToken}`
      })
    }
  );

  if (response.status === 401) {
    return false;
  }

  return true;
}

async function getWani(path: string, expiresIn: Duration, storage: IndexedDbStore, accessToken: string): Promise<any> {
  const existingValue = await storage.get<string>(`wani:${accessToken}:${path}`);
  if (!existingValue) {
    const response = await fetch(
      `https://api.wanikani.com/${path}`, 
      { 
        method: "GET", 
        headers: new Headers({
          "Authorization": `Bearer ${accessToken}`
        })
      }
    );

    if (response.status === 401) {
      throw new AuthError();
    }

    const data = await response.json();

    await storage.set(`wani:${accessToken}:${path}`, JSON.stringify(data.data), expiresIn);

    return data.data;
  } else {
    return JSON.parse(existingValue);
  }
};

export async function user(storage: IndexedDbStore, accessToken: string): Promise<UserResource> {
  const data = await getWani("v2/user", Duration.fromObject({ hours: 1 }), storage, accessToken);
  return new UserResource(data);
}

export async function summary(storage: IndexedDbStore, accessToken: string): Promise<SummaryResource> {
  const data = await getWani("v2/summary", Duration.fromObject({ minutes: 30 }), storage, accessToken);

  const subjectIds = uniq<number>(data.reviews.flatMap((r: any) => r.subject_ids));
  const typedSubjects = await subjectTypes(subjectIds, storage);

  return new SummaryResource(typedSubjects);
}
