import { JSX } from "solid-js/jsx-runtime";
import { Component, Suspense } from "solid-js";

import styles from "./UserSummary.module.scss";
import { TextSkeleton } from "./Skeleton";
import Pill, { PillStyle } from "./Pill";
import { useUser } from "../contexts/WaniKani";


const UserSummary: Component = (): JSX.Element => {
  const user = useUser();

  return (
    <div class={styles.container}>
      <div class={styles.username}>
        <Suspense fallback={<TextSkeleton text="Hello someguy42" />}>
          Hello {user()?.username}
        </Suspense>
      </div>

      <Suspense fallback={<Pill skeleton={true} style={PillStyle.Default}>Level: 42</Pill>}>
        <Pill style={PillStyle.Default}>Level: {user()?.level}</Pill>
      </Suspense>
    </div>
  );
};

export default UserSummary;