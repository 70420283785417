import { TransferrableAudioBuffer } from '@/workers/Encoder';
import { proxy } from 'comlink';

const instance = new ComlinkWorker<typeof import("@/workers/Encoder")>(
  new URL("@/workers/Encoder", import.meta.url),
  {
    type: "module"
  }
);

export async function encode(audioBuffer: AudioBuffer, kbps: number, progressFn: (progress: number) => void): Promise<Uint8Array> {
  const transferrableAudioBuffer: TransferrableAudioBuffer = {
    length: audioBuffer.length,
    sampleRate: audioBuffer.sampleRate,
    left: audioBuffer.getChannelData(0),
    right: audioBuffer.getChannelData(1)
  }
  
  return await instance.encode(transferrableAudioBuffer, kbps, proxy(progressFn));
}