import { Component, JSX, Suspense } from "solid-js";
import { TextSkeleton } from "./Skeleton";
import { useSummary } from "../contexts/WaniKani";

const ReviewCount: Component = (): JSX.Element => {
  const summary = useSummary();

  return (
    <Suspense fallback={<TextSkeleton text="42" />}>
      <strong>{summary()?.reviewCount}</strong>
    </Suspense>
  );
};

export default ReviewCount;