import { type JSX, type Component, ErrorBoundary, createSignal } from "solid-js";

import ErrorHandler from "./ErrorHandler";
import GenerateForm from "./GenerateForm";
import Generate from "./Generate";
import { DigestConfig } from "../services/Generator";
import { withSentryErrorBoundary } from "@sentry/solid";

const SentryErrorBoundary = withSentryErrorBoundary(ErrorBoundary);

const Main: Component = (): JSX.Element => {
  const [config, setConfig] = createSignal<DigestConfig | undefined>(undefined);

  const onBack = () => {
    setConfig(undefined);
  }

  return (
    <SentryErrorBoundary fallback={(err) => <ErrorHandler err={err} />}>
      {!config() && <GenerateForm setConfig={(config) => setConfig(config)} />}
      {config() && <Generate config={config()!} onBack={onBack} />}
    </SentryErrorBoundary>
  )
}

export default Main