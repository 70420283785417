import Crunker from "crunker";
import SummaryResource from "@/resources/SummaryResource";
import { segment } from "@/clients/Backend";

import introUrl from "@/assets/intro.mp3";
import outroUrl from "@/assets/outro-fade.mp3";
import gongUrl from "@/assets/gong.mp3";

import { encode } from "./Encoder";
import pLimit from "p-limit";
import { DateTime } from "luxon";
import compact from "lodash-es/compact";
import random from "lodash-es/random";

const limit = pLimit(5);

export function makeConfig(summary: SummaryResource, settings: DigestSettings): DigestConfig {
  return {
    ...settings,
    subjectIds: summary.subjectIds,
  };
}

/// TODO:
/// There really should be 2 modes:
/// Review mode: what we currently have - where you hear a question and try and answer it
/// Learning mode: where all your reviews are read out with context sentences and the mnemonic so you can learn by listening
/// If we added learning mode, you could pick to have review mode at the end

export enum ReviewStyle {
  JapaneseFirst = "japaneseFirst",
  EnglishFirst = "englishFirst",
  Randomize = "randomize",
}

export enum ReviewSeparator {
  ReviewNumber = "reviewNumber",
  Pause = "pause",
  Gong = "gong",
}

export type DigestSettings = {
  reviewStyle: ReviewStyle;
  pauseBeforeAnswerDuration: number;
  reviewSeparator: ReviewSeparator;

  audioFormat: AudioFormat;
}

export type AudioFormat = WaveAudioFormat | Mp3AudioFormat;

export type WaveAudioFormat = {
  type: OutputType.Wave;
};

export enum Mp3Quality {
  Low = "low",
  Medium = "medium",
  High = "high"
};

export enum OutputType {
  Wave = "wav",
  Mp3 = "mp3",
}

export type Mp3AudioFormat = {
  type: OutputType.Mp3;
  quality: Mp3Quality;
}

export type DigestConfig = DigestSettings & {
  subjectIds: number[];
};

export type GenerationState = {
  reviews: { started: boolean, completed: boolean, stats: { total: number, complete: number } },
  downloads: { started: boolean, completed: boolean, stats: { total: number, complete: number } },
  encoding: { skipped: boolean, started: boolean, completed: boolean, stats: { progress: number } },
};

export type StateChangeFn = (state: GenerationState) => void;

export function initialState(config: DigestConfig): GenerationState {
  return {
    reviews: { started: true, completed: false, stats: { total: config.subjectIds.length, complete: 0 } },
    downloads: { started: true, completed: false, stats: { total: 0, complete: 0 } },
    encoding: { skipped: config.audioFormat.type !== OutputType.Mp3, started: false, completed: false, stats: { progress: 0.0 } },
  }
}

export type GenerationResult = {
  url: string,
  filename: string
}

export const generate = async (config: DigestConfig, onStateChange: StateChangeFn): Promise<GenerationResult> => {
  const state = initialState(config);
  const controller = new AbortController()
  const signal = controller.signal;
  const crunker = new Crunker();

  const updateState = (fn: (state: GenerationState) => void) => {
    fn(state);
    onStateChange(structuredClone(state));
  };

  const downloadStarted = () => {
    updateState((s) => {
      s.downloads.stats.total += 1;
    });
  };

  const downloadFinished = () => {
    updateState((s) => {
      s.downloads.stats.complete += 1;
      if (s.downloads.stats.total === s.downloads.stats.complete) {
        s.downloads.completed = true;
      }
    });
  };

  const reviewFinished = () => {
    updateState((s) => {
      s.reviews.stats.complete += 1;
      if (s.reviews.stats.total === s.reviews.stats.complete) {
        s.reviews.completed = true;
      }
    });
  };

  const getUrl = async (url: string): Promise<AudioBuffer> => {
    downloadStarted();

    const resp = await fetch(url, { method: "GET", signal });

    if (!resp.ok) {
      throw new Error(`Unexpected response ${resp.status}`);
    }

    const blob = await resp.blob();

    let [buffer] = await crunker.fetchAudio(blob);

    downloadFinished();

    return convertMonoToStereo(crunker, buffer);
  };

  const getSegment = async (info: any): Promise<AudioBuffer> => {
    downloadStarted();

    const blob = await limit(() => segment(info, signal));

    let [buffer] = await crunker.fetchAudio(blob);

    downloadFinished();

    return convertMonoToStereo(crunker, buffer);
  };

  const overlap = async (aP: Promise<AudioBuffer>, bP: Promise<AudioBuffer>, startBAt: number): Promise<AudioBuffer> => {
    const [a, b] = await Promise.all([aP, bP]);

    const paddedB = crunker.padAudio(b, 0, a.duration - startBAt);

    return crunker.mergeAudio([a, paddedB]);
  };

  const slightPause = async (): Promise<AudioBuffer> => {
    return crunker.context.createBuffer(1, (Math.random() + 0.3) * crunker.context.sampleRate, crunker.context.sampleRate);
  };

  const longPause = async (seconds: number = 5): Promise<AudioBuffer> => {
    return crunker.context.createBuffer(1, seconds * crunker.context.sampleRate, crunker.context.sampleRate);
  };

  const joinSegments = async (segments: Promise<AudioBuffer | null>[]): Promise<AudioBuffer> => {
    return crunker.concatAudio(compact(await Promise.all(segments)));
  };

  const reviewSeparator = async (number: number): Promise<AudioBuffer | null> => {
    switch (config.reviewSeparator) {
      case ReviewSeparator.ReviewNumber:
      return getSegment({ number: { number } });
      case ReviewSeparator.Gong:
      if (number === 1) {
        return null;
      }

      return getUrl(gongUrl);
      case ReviewSeparator.Pause:
      if (number === 1) {
        return null;
      }

      return longPause(1);
    }
  };

  const reviewStyleToModes = (): [string, string] => {
    switch (config.reviewStyle) {
      case ReviewStyle.EnglishFirst:
      return ["meaning", "reading"];
      case ReviewStyle.JapaneseFirst:
      return ["reading", "meaning"];
      case ReviewStyle.Randomize:
      return random(1, 10) > 5 ? ["reading", "meaning"] : ["meaning", "reading"];
    }
  };

  const encodeBuffer = async (buffer: AudioBuffer) => {
    switch (config.audioFormat.type) {
      case OutputType.Wave:
      const result = crunker.export(buffer);

      return { url: result.url, filename: `${DateTime.now().toISODate()}.wav` }
      case OutputType.Mp3:
      updateState((s) => {
        s.encoding.started = true;
      });

      const out = await encode(buffer, qualityToKbps(config.audioFormat.quality), (progress) => {
        updateState((s) => {
          s.encoding.stats.progress = progress;
        });
      });

      updateState((s) => {
        s.encoding.completed = true;
      });

      const blob = new Blob([out], { type: "audio/mp3" });

      return { url: URL.createObjectURL(blob), filename: `${DateTime.now().toISODate()}.mp3` }
    }
  }

  const reviewSegments = async (id: number, number: number): Promise<AudioBuffer> => {
    const [firstMode, secondMode] = reviewStyleToModes();

    const segments = [
      reviewSeparator(number),
      slightPause(),
      getSegment({ subject: { id, mode: firstMode }}),
      longPause(config.pauseBeforeAnswerDuration),
      getSegment({ subject: { id, mode: secondMode }}),
      longPause(1)
    ];

    const buffer = await joinSegments(segments);

    reviewFinished();

    return buffer;
  };


  const segments = [
    overlap(
      getUrl(introUrl),
      getSegment({ intro: { number_of_reviews: config.subjectIds.length }}),
      1.1,
    ),
    // slightPause(),
    // getTts("I'm going to read out each vocabulary words primary meaning, then give you a few moments to consider the pronunciation, before reading it out for you."),
    longPause(1),
    ...config.subjectIds.map((id, i) => reviewSegments(id, i + 1)),
    // overlap(
    // getTts("That's all for now... I'll see you next time!"),
    getUrl(outroUrl),
    // 1.1,
    // )
  ];

  try {
    const finalBuffer = await joinSegments(segments);

    return await encodeBuffer(finalBuffer);
  } catch (e) {
    controller.abort();
    throw e;
  }
};

const qualityToKbps = (quality: Mp3Quality): number => {
  switch (quality) {
    case Mp3Quality.Low:
    return 128;
    case Mp3Quality.Medium:
    return 160;
    case Mp3Quality.High:
    return 256;
  }
};

const convertMonoToStereo = (crunker: Crunker, audioBuffer: AudioBuffer) => {
  if (audioBuffer.numberOfChannels !== 1) {
    return audioBuffer;
  }

  const stereoBuffer = crunker.context.createBuffer(
    2,
    audioBuffer.length,
    audioBuffer.sampleRate
  );

  const normalizationFactor = 1 / Math.sqrt(2);
  stereoBuffer
  .getChannelData(0)
  .set(
    audioBuffer
    .getChannelData(0)
    .map((sample) => sample * normalizationFactor)
  );
  stereoBuffer
  .getChannelData(1)
  .set(
    audioBuffer
    .getChannelData(0)
    .map((sample) => sample * normalizationFactor)
  );

  return stereoBuffer;
};
