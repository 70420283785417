import { Component, JSX } from "solid-js";
import styles from "./Background.module.scss";

import leftLandscapeUrl from "@/assets/background/left_landscape_opt.svg";
import leftBuildingsUrl from "@/assets/background/left_buildings_opt.svg";
import rightLandscapeBackUrl from "@/assets/background/right_landscape_back_opt.svg";
import rightLandscapeFrontUrl from "@/assets/background/right_landscape_front_opt.svg";
import rightBuildingsUrl from "@/assets/background/right_buildings_opt.svg";


import leftReflectionUrl from "@/assets/background/left_reflection_opt.svg";
import rightReflectionUrl from "@/assets/background/right_reflection_opt.svg";

import volcanoUrl from "@/assets/background/volcano_opt.svg";
import volcanoReflectionUrl from "@/assets/background/volcano_reflection_opt.svg";

import sunUrl from "@/assets/background/sun_opt.svg";
import birdsUrl from "@/assets/background/birds_opt.svg";
import cloud1Url from "@/assets/background/cloud_1_opt.svg";
import cloud2Url from "@/assets/background/cloud_2_opt.svg";

import toriiUrl from "@/assets/background/torii_opt.svg";
import waterUrl from "@/assets/background/water_opt.svg";

export const Background: Component = (): JSX.Element => {
  return (
    <div class={styles.background}>
      <div class={styles.water} />
      <div class={styles.inner}>
        <img src={volcanoUrl} class={styles.volcano} />
        <img src={volcanoReflectionUrl} class={styles.volcanoReflection} />

        <img src={sunUrl} class={styles.sun} />
        <img src={cloud1Url} class={styles.cloudsLeft} />
        <img src={cloud2Url} class={styles.cloudsRight} />
        <img src={birdsUrl} class={styles.birdsRight} />
        <img src={birdsUrl} class={styles.birdsLeft} />

        <img src={rightLandscapeBackUrl} class={styles.rightLandscapeBack} />
        <img src={rightBuildingsUrl} class={styles.rightBuildings} />
        <img src={rightLandscapeFrontUrl} class={styles.rightLandscapeFront} />

        <img src={rightReflectionUrl} class={styles.rightReflection} />
        <img src={leftReflectionUrl} class={styles.leftReflection} />
        
        <img src={leftLandscapeUrl} class={styles.leftLandscape} />
        <img src={leftBuildingsUrl} class={styles.leftBuildings} />

        <img src={waterUrl} class={styles.waterDisruption} />
        <img src={toriiUrl} class={styles.torii} />
      </div>
    </div>
  )
}

export default Background;
