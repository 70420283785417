import { Component, createSignal, JSX } from "solid-js";
import Box from "./Box";

import styles from "./GetAccessToken.module.scss";

import Button from "./Button";
import Heading from "./Heading";
import Code from "./Code";
import Link from "./Link";
import ButtonGroup from "./ButtonGroup";
import { sleep } from "../utils";
import { useAccessToken } from "../contexts/WaniKani";

const GetAccessToken: Component = (): JSX.Element => {  
  const { setAccessToken, validateAccessToken } = useAccessToken();

  const [value, setValue] = createSignal("");
  const [loading, setLoading] = createSignal(false);
  const [error, setError] = createSignal("");

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    await sleep(100);

    const accessToken = value().trim();

    if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(accessToken)) {
      setError("This doesn't look like a valid WaniKani token");
      setLoading(false);
      return;
    }

    const valid = await validateAccessToken(value());
    if (valid) {
      setAccessToken(accessToken);
    } else {
      setError("This token isn't working, are you sure it's correct?");
    }

    setLoading(false);
  };

  return (
    <Box>
      <Heading>Welcome!</Heading>

      <p>
        We're excited to help you learn kanji and vocabulary with podcasts tailored to your daily WaniKani reviews.
      </p>

      <p>
        To get started, just enter your access token for WaniKani so we can grab your reviews. 
      </p>

      <p>
        We only require the <Code>all_data:read</Code> permission. This gives us read-only access to your data.
      </p>
      <p>
        <Link href="https://www.wanikani.com/settings/personal_access_tokens" newWindow={true}>
          You can find your WaniKani access tokens settings here
        </Link>
      </p>

      <div>
        <input class={styles.input} type="text" placeholder="Enter your access token..." spellcheck={false} value={value()} onChange={(e) => setValue(e.currentTarget.value)} disabled={loading()} />
        {error() && (<span class={styles.error}>{error()}</span>)}
      </div>

      <ButtonGroup>
        <Button text="Let's go" onClick={handleSubmit} loading={loading()} />
      </ButtonGroup>
    </Box>
  )
}

export default GetAccessToken;