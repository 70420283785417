import { createContext, createMemo, createResource, createSignal, useContext, } from "solid-js";
import type { JSX, Component, ParentProps, Resource, Accessor, ResourceReturn } from "solid-js";
import { makePersisted } from "@solid-primitives/storage";
import { summary, user, validateAccessToken } from "../clients/WaniKani";
import { useStorage } from "./Storage";
import UserResource from "../resources/UserResource";
import SummaryResource from "../resources/SummaryResource";

type WaniKaniContextData = {
  setAccessToken: (value: string) => void,
  clearAccessToken: () => void,
  isSetup: () => boolean
  userResourceAccessor: Accessor<ResourceReturn<UserResource, unknown> | null>,
  summaryResourceAccessor: Accessor<ResourceReturn<SummaryResource, unknown> | null>,
};

const WaniKaniContext = createContext<WaniKaniContextData>();

export const Provider: Component<ParentProps> = (props): JSX.Element => {
  const storage = useStorage();
  const [get, set] = makePersisted(createSignal(""), { storage: localStorage, name: "wanikani_access_token" });

  const userResourceAccessor = createMemo(() => {
    if (get()) {
      return createResource(() => user(storage, get()))
    } else {
      return null;
    }
  });

  const summaryResourceAccessor = createMemo(() => {
    if (get()) {
      return createResource(() => summary(storage, get()))
    } else {
      return null;
    }
  });

  const value = {
    setAccessToken: (value: string) => set(value),
    clearAccessToken: () => set(""),
    isSetup: () => get() !== "",
    userResourceAccessor,
    summaryResourceAccessor
  };

  return <WaniKaniContext.Provider value={value}>{props.children}</WaniKaniContext.Provider>;
};

export const useUser = (): Resource<UserResource> => {
  const { userResourceAccessor } = useContext(WaniKaniContext)!;
  const userResource = userResourceAccessor();
  
  if (userResource) {
    return userResource[0];
  } else {
    throw new Error("No user resource set");
  }
};

export const useSummary = (): Resource<SummaryResource> => {
  const { summaryResourceAccessor } = useContext(WaniKaniContext)!;
  const summaryResource = summaryResourceAccessor();
  
  if (summaryResource) {
    return summaryResource[0];
  } else {
    throw new Error("No summary resource set");
  }
};

export const useAccessToken = () => {
  const value = useContext(WaniKaniContext);

  return { isSetup: value!.isSetup, setAccessToken: value!.setAccessToken, clearAccessToken: value!.clearAccessToken, validateAccessToken };
};