import { createContext, useContext, } from "solid-js";
import type { JSX, Component, ParentProps } from "solid-js";
import { IndexedDbStore } from "../services/IndexedDbStore";

const StorageContext = createContext<IndexedDbStore>();

export const Provider: Component<ParentProps> = (props): JSX.Element => {
  const client = new IndexedDbStore();

  return <StorageContext.Provider value={client}>{props.children}</StorageContext.Provider>;
};

export const useStorage = (): IndexedDbStore => {
  return useContext(StorageContext)!
};