import { Component, JSX, ParentProps } from "solid-js";
import styles from "./Box.module.scss";
import clsx from "clsx";

const Box: Component<ParentProps<{ class?: string }>> = (props): JSX.Element => {
  return (
    <div class={clsx(styles.box, props.class)}>
      {props.children}
    </div>
  )
}

export default Box
