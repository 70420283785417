class UserResource {
  private _data: any;

  private _username: string;
  private _level: number;

  constructor(data: any) {
    this._data = data;
    this._username = data.username;
    this._level = data.level;
  }

  get data() {
    return this._data;
  }

  get username() {
    return this._username;
  }

  get level() {
    return this._level;
  }
}

export default UserResource;