import { Component, JSX } from "solid-js";
import Heading from "./Heading";
import Button, { ButtonStyle } from "./Button";
import ButtonGroup from "./ButtonGroup";
import { saveBlobUrl } from "@/utils";
import { GenerationResult } from "@/services/Generator";

const GenerateComplete: Component<{ generationResult: GenerationResult, onBack: () => void }> = (props): JSX.Element => {
  return (
    <>
      <Heading>Your podcast is ready!</Heading>
      
      <p>
        You can listen to it now using the player below or download it to enjoy on any device at your convenience.
      </p>

      <audio autoplay={false} controls={true} src={props.generationResult.url} />
      
      <ButtonGroup>
        <Button text="Back" style={ButtonStyle.Secondary} onClick={props.onBack}></Button>
        <Button text="Download" onClick={() => saveBlobUrl(props.generationResult.url, props.generationResult.filename)}></Button>
      </ButtonGroup>
    </>
  );
};

export default GenerateComplete;