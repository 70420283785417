import { Component, JSX, Match, Switch } from "solid-js";
import Heading from "./Heading";
import Box from "./Box";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import { useAccessToken } from "../contexts/WaniKani";

export const Fallback: Component = (): JSX.Element => {
  return (
    <>
      <Heading>Something went wrong</Heading>

      <p>
        There was an unexpected error and we can't continue. Sorry about that!
      </p>
    </>
  )
};

const AuthError: Component = (): JSX.Element => {
  const { clearAccessToken } = useAccessToken();

  return (
    <>
      <Heading>WaniKani access issue</Heading>

      <p>
        We're having trouble talking to WaniKani, they returned an "unauthorized" error... 
      </p>
      <p>
        This is likely because the access token used was typed incorrectly or has been removed from your account.
      </p>

      <ButtonGroup>
        <Button onClick={clearAccessToken} text="Setup a new access token" />
      </ButtonGroup>
    </>
  )
};

const ErrorHandler: Component<{ err: Error }> = (props): JSX.Element => {
  return (
    <Box>
      <Switch fallback={<Fallback />}>
        <Match when={props.err.name === "AuthError"}>
          <AuthError />
        </Match>
      </Switch>
    </Box>
  );
}

export default ErrorHandler;