import { Component, JSX } from "solid-js";

import styles from "./RadioButton.module.scss";
import { useField } from "./Field";

export type RadioButtonProps = {
  value: string, 
  selected: boolean, 
  onSelected: () => void,

  label: JSX.Element,
  help?: JSX.Element,
};

const RadioButton: Component<RadioButtonProps> = (props): JSX.Element => {
  const { name } = useField();

  const handleChange: JSX.ChangeEventHandler<HTMLInputElement, Event> = (e) => {
    if (e.currentTarget.checked) {
      props.onSelected();
    }
  };

  return (
    <label class={styles.radioButton}>
      <input type="radio" name={name} value={props.value} checked={props.selected} onChange={handleChange} />
      
      <div>
        {props.label}
        {props.help && (
          <p class={styles.help}>{props.help}</p>
        )}
      </div>
    </label>
  )
};

export default RadioButton;
