import { Component, createMemo, JSX, ParentProps, Signal } from "solid-js";

import styles from "./Expander.module.scss";
import clsx from "clsx";

enum ArrowDirection {
  Up = "up",
  Down = "down"
};

const Arrow: Component<{ direction: ArrowDirection }> = (props): JSX.Element => {
  return (
    <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg" class={clsx(styles.arrow, props.direction == ArrowDirection.Up && styles.up)}>
      <path d="M0.372093 1.29281C0.370607 1.03751 0.445185 0.787554 0.586317 0.574806C0.727447 0.362057 0.92874 0.196157 1.16453 0.0982567C1.40031 0.00035673 1.65991 -0.0251077 1.91023 0.02511C2.16055 0.0753277 2.39024 0.198952 2.57002 0.380222L9.36945 7.19251L16.1689 0.380223C16.4109 0.138189 16.7392 0.00221589 17.0815 0.0022159C17.251 0.00221591 17.4188 0.0355979 17.5754 0.100456C17.7319 0.165315 17.8742 0.26038 17.9941 0.380223C18.1139 0.500066 18.209 0.64234 18.2738 0.798922C18.3387 0.955504 18.3721 1.12333 18.3721 1.29281C18.3721 1.6351 18.2361 1.96337 17.9941 2.2054L10.282 9.91742C10.0412 10.1568 9.71545 10.2912 9.37588 10.2912C9.03631 10.2912 8.71054 10.1568 8.46972 9.91742L0.757692 2.2054C0.636244 2.08636 0.539627 1.9444 0.473436 1.78775C0.407246 1.6311 0.372799 1.46287 0.372093 1.29281Z" />
    </svg>
  );
}

const Expander: Component<ParentProps<{ title: string, open: Signal<boolean>, disabled: boolean }>> = (props): JSX.Element => {
  const [isOpen, setOpen] = props.open;

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    
    setOpen((v) => !v);
  };

  const actuallyOpen = createMemo(() => isOpen() && !props.disabled);

  return (
    <div class={styles.expander}>
      <div class={styles.bar} onClick={handleClick}>
        <span>{props.title}</span>
        <Arrow direction={actuallyOpen() ? ArrowDirection.Up : ArrowDirection.Down} />
      </div>
      <div class={clsx(styles.content, actuallyOpen() && styles.openContent)}>
        {props.children}
      </div>
    </div>
  );
};

export default Expander;