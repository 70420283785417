import { Component, JSX, ParentProps } from "solid-js";

import styles from "./Code.module.scss";

const Code: Component<ParentProps> = (props): JSX.Element => {
  return (
    <code class={styles.code}>{props.children}</code>
  );
};

export default Code;