import { Component, JSX } from "solid-js";
import ReviewCount from "./ReviewCount";

const IntroMessage: Component = (): JSX.Element => {
  return (
    <>
      <p>
        You have <ReviewCount /> vocabulary reviews ready for today. We'll generate them into a podcast for you to listen to now or save it and listen later on any device. 
      </p>
      <p>
        The defaults are sensible but you can use the configuration settings below to customize the podcast to your liking &mdash; we'll remember your settings, so you only need to set them once.
      </p>
    </>
  );
};

export default IntroMessage;