import { Component, ParentProps } from "solid-js";
import { JSX } from "solid-js/jsx-runtime";

import styles from "./ButtonGroup.module.scss";

const ButtonGroup: Component<ParentProps> = (props): JSX.Element => {
  return (
    <div class={styles.buttonGroup}>
      {props.children}
    </div>
  )
};

export default ButtonGroup;