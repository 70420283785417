import { Component, JSX, ParentProps } from "solid-js";

import styles from "./Link.module.scss";

const Link: Component<ParentProps<{ href: string, newWindow?: boolean }>> = (props): JSX.Element => {
  return (
    <a class={styles.link} href={props.href} target={props.newWindow ? "_blank" : undefined}>
      {props.children}
    </a>
  );
};

export default Link;